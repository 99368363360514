<template>
  <v-container fluid>
    <v-row class="mb-2">
      <v-col class="text-center text-h4 col-12">Статистика</v-col>
    </v-row>
    <v-overlay :value="loading" :absolute="true">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row v-if="!this.loading">
      <v-col
        @click="showUsersList = true"
        class="cursor-pointer col-xl-3 col-md-6 col-12"
      >
        <v-card class="purple--text">
          <v-card-title>
            Общий баланс пользователей
          </v-card-title>
          <v-card-text v-if="dayProfit">
            <h1>
              {{ totalUserBalance.totalBalance.USD }} $
              {{ totalUserBalance.totalBalance.RUB }} Р.
            </h1>
            <v-dialog v-model="showUsersList" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Список пользователей</span>
                </v-card-title>
                <v-card-text>
                  <v-data-table
                    :headers="usersDataHeader"
                    :items="totalUserBalance.users"
                    :loading="loading"
                    loading-text="Загрузка... Пожалуйста подождите"
                  >
                    <template v-slot:item.balance="{ item }">
                      <div>{{ (item.balance / 100).toFixed(2) }}</div>
                    </template>
                    <template v-slot:item.nick="{ item }">
                      <router-link tag="div" :to="`/users/${item.id}`">
                        <v-btn color="blue">{{ item.nick }}</v-btn>
                      </router-link>
                    </template>
                  </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-btn color="red" @click="showUsersList = false"
                    >Закрыть</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
          <v-card-subtitle>
            На всех аккаунтах
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col class="col-xl-3 col-md-6 col-12">
        <v-card class="blue--text">
          <v-card-title>
            Чистый поток средств
          </v-card-title>
          <v-card-text v-if="dayProfit">
            <h1>{{ dayProfit.dollars }} $ {{ dayProfit.roubles }} Р.</h1>
          </v-card-text>
          <v-card-subtitle>
            За сегодня
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col class="col-xl-3 col-md-6 col-12">
        <v-card class="orange--text">
          <v-card-title>
            Депозитов
          </v-card-title>
          <v-card-text>
            <h1>{{ dayDeposit.dollars }} $ {{ dayDeposit.roubles }} Р.</h1>
          </v-card-text>
          <v-card-subtitle>
            За сегодня
          </v-card-subtitle>
        </v-card>
      </v-col>

      <v-col class="col-xl-3 col-md-6 col-12">
        <v-card class="red--text">
          <v-card-title>
            Новые игроки
          </v-card-title>
          <v-card-text>
            <h1>{{ this.playersCount }}</h1>
          </v-card-text>
          <v-card-subtitle>
            За сегодня
          </v-card-subtitle>
        </v-card>
      </v-col>

      <v-col class="col-xl-3 col-md-6 col-12">
        <v-card class="deep-orange--text">
          <v-card-title>
            Выводов
          </v-card-title>
          <v-card-text v-if="dayWithdraw">
            <h1>{{ dayWithdraw.dollars }} $ {{ dayWithdraw.roubles }} Р.</h1>
          </v-card-text>
          <v-card-subtitle>
            За сегодня
          </v-card-subtitle>
        </v-card>
      </v-col>

      <v-col class="col-xl-3 col-md-6 col-12">
        <v-card class="green--text">
          <v-card-title>
            Новых рефов
          </v-card-title>
          <v-card-text>
            <h1>{{ this.referralsCount }}</h1>
          </v-card-text>
          <v-card-subtitle>
            За сегодня
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <div class="row" v-if="!this.loading">
      <div class="col-12">
        <Chart :data="weekChart">
          <template slot="header">
            <span>Статистика оборота</span>
          </template>
          <template slot="subtitle">
            <span>За последние 7 дней</span>
          </template>
        </Chart>
      </div>
    </div>
    <div class="row" v-if="!this.loading">
      <div class="col-12">
        <Chart :data="monthChart">
          <template slot="header">
            <span>Статистика оборота</span>
          </template>
          <template slot="subtitle">
            <span>За последние 30 дней</span>
          </template>
        </Chart>
      </div>
    </div>
  </v-container>
</template>
<script>
import { api } from "@/instances/http";
import Chart from "@/components/Chart";

export default {
  components: {
    Chart
  },
  data() {
    return {
      loading: true,
      dayProfit: {
        dollars: 0,
        roubles: 0
      },
      dayDeposit: {
        dollars: 0,
        roubles: 0
      },
      playersCount: 0,
      gamesCount: 0,
      monthChart: 0,
      weekChart: 0,
      dayWithdraw: 0,
      referralsCount: 0,
      totalUserBalance: {
        totalBalance: {
          RUB: 0,
          USD: 0
        },
        users: []
      },
      showUsersList: false,
      usersDataHeader: [
        { text: "id", value: "id" },
        { text: "ник", value: "nick" },
        { text: "Баланс", value: "balance" }
      ]
    };
  },
  methods: {
    getSummary() {
      this.loading = true;
      api.get("/statistic/summary").then(res => {
        const data = res.data;
        this.dayProfit = data.dayProfit;
        this.playersCount = data.playersCount;
        this.dayDeposit = data.dayDeposit;
        this.referralsCount = data.referralsCount;
        this.dayWithdraw = data.dayWithdraw;
        this.weekChart = data.weekSummary;
        this.monthChart = data.monthSummary;
        this.totalUserBalance = data.totalUserBalance;
        this.loading = false;
      });
    }
  },
  created: function() {
    this.getSummary();
  }
};
</script>
<style lang="scss">
.cursor-pointer {
  cursor: pointer;
}
</style>
